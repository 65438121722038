
import Document from '~/components/util/docs/Document'
import { httpClient } from '~/utils/axios'
import Compressor from 'compressorjs'
import heic2any from 'heic2any';
import axios from 'axios'
import { scrollToTop } from '~/utils/tool'
import draggable from "vuedraggable";

export default {
  components: {
    Document,
    draggable,
  },
  props: {
    inline: {type: Boolean, default: false},
    key_index: {type: String, default: 1},
    doc_type: { type: String, default: "other" },
    showCreatedAt: {type: Boolean, default: true},
    showTool: {type: Boolean, default: true},
    canUpload: {type: Boolean, default: false},
    canDelete: {type: Boolean, default: false},
    onlyImage: {type: Boolean, default: false},
    onlyMedia: {type: Boolean, default: false},
    multiple: {type: Boolean, default: true},
    isSizeXS: {type: Boolean, default: false},
    docs: { type: Array },
    draggable: {type: Boolean, default: false},
    client_id: {type: Number, default: null},
    client_trend_id: {type: Number, default: null},
    lesson_id: {type: Number, default: null},
    training_event_id: {type: Number, default: null},
  },
  data() {
    return {
      isBusy: false,
      current_docs: [],
      accept: "*/*",

      // ファイル進捗
      progre_set: [],
      file_index: 0,
      file_length: null,
      allProgre: 0,
    }
  },
  async mounted() {
    let self = this
    // 既存ドキュメント
    self.current_docs = _.cloneDeep(this.docs)
    if(self.onlyImage) self.accept = "image/*"
    if(self.onlyMedia) self.accept = 'audio/*,video/*,image/*'
  },
  watch: {
    docs(val){
      this.current_docs = _.cloneDeep(val)
    },
  },
  computed: {
    filterdImages(){
      let images = []
      this.current_docs.forEach(d => {
        if(d.file_type === "img") images.push(d.img.url)
        // if(d.file_type === "pdf") images.push(d.file.url)
      })
      return images
    },
  },
  methods: {
    // 画像スライド
    showImageSlide(doc){
      let initialViewIndex = _.findIndex(this.filterdImages, (img) => {
        return img == doc.img.url;
      })
      let viewer = this.$viewerApi({
        images: this.filterdImages,
        options: {
          initialViewIndex: initialViewIndex,
          movable: true,
          scalable: false,
          toolbar: {
            zoomIn: true,
            zoomOut: true,
            reset: true,
            prev: true,
            next: true,
            rotateRight: true,
            download: async () => {
              let current_docs = _.filter(this.current_docs, (doc)=>{
                return doc.file_type === "img" && doc.img.url === viewer.image.src
              })
              console.log("viewer current_img docs", current_docs[0])
              await httpClient
                .get(`/cmp/floor/docs/${current_docs[0].uid}/get_image`,{responseType: "blob"})
                .then(async (res) => {
                  window.storeCtl.commit("loading/stop")
                  var FILE = window.URL.createObjectURL(new Blob([res.data], { type: 'image/png' }));  
                  const a = document.createElement("a");
                  a.href = FILE
                  a.download = current_docs[0].origin_file_name
                  a.click();
                })
                .finally(() => {
                  window.storeCtl.commit("loading/stop")
                })
            }
          }
        },
      })
    },

    // change PDF
    checkHasPrevPdf(doc, doc_idx){
      if(doc.file_type !== "pdf") return
      let hasPrefPdf = false
      for(let i = 0; i < doc_idx; i++){
        if(this.current_docs[i].file_type === "pdf") hasPrefPdf = true
      }
      return hasPrefPdf
    },
    checkHasNextPdf(doc, doc_idx){
      if(doc.file_type !== "pdf") return
      let hasNextPdf = false
      for(let i = doc_idx + 1; i < this.current_docs.length; i++){
        if(this.current_docs[i].file_type === "pdf") hasNextPdf = true
      }      
      return hasNextPdf
    },
    openPrefPdf(doc_idx){
      // console.log("openPrefPdf", doc_idx)
      let selectedDocIndex = null
      for(let i = 0; i < doc_idx; i++){
        if(this.current_docs[i].file_type === "pdf"){
          selectedDocIndex = i
        }
      }
      this.$refs.document[selectedDocIndex].viewPdf()
    },
    openNextPdf(doc_idx){
      // console.log("openNextPdf", doc_idx)
      let selectedDocIndex = null
      for(let i = doc_idx + 1; i < this.current_docs.length; i++){
        if(this.current_docs[i].file_type === "pdf"){
          selectedDocIndex = i
          break
        }
      }     
      if(selectedDocIndex) this.$refs.document[selectedDocIndex].viewPdf()
    },

    // 進捗
    updateAllProgre(){
      if(this.file_length == null || this.file_length == 0) return "-"
      this.allProgre = parseInt(_.sum(this.progre_set) / this.file_length)
    },

    //　ドキュメント削除後
    docDeleted(doc){
      // 生きているdoc_idsを引き数字
      this.current_docs = _.filter(this.current_docs, (d) => {return d.id !== doc.id})
      // let new_current_doc_ids = _.map(this.current_docs, 'id');
      this.$emit("docsUpdated", this.current_docs)
    },

    async fileSelected(event) {
      let self = this
      // 容量チェック
      let hasSizeOver = false
      for(let i = 0; i < event.target.files.length; i++){
        const file = event.target.files[i]
        if(file.size > 1000000 * 10) hasSizeOver = true
      }
      if(hasSizeOver){
        window.storeCtl.commit("alert/setError", "1つのファイルのサイズは10MB以下にしてください")
        self.files = []
        return 
      }

      // 開始
      self.isBusy = true
      // 進捗バー準備
      this.progre_set = []
      for(let i = 0; i < event.target.files.length; i++){
        this.progre_set.push(0)
      }
      this.file_length = event.target.files.length
      // アップロード
      for(let i = 0; i < event.target.files.length; i++){
        this.file_index = i + 1
        const file = event.target.files[i]
        await this.docUpload(file, i) 
      }
      self.isBusy = false
      // window.storeCtl.commit("alert/setSuccess", "アップロード完了")
      self.$emit("docsUpdated", self.current_docs)
    },
    async docUpload(file, file_index){
      let self = this
      let doc_info = {}
      if(self.client_id) doc_info.client_id = self.client_id
      if(self.client_trend_id) doc_info.client_trend_id = self.client_trend_id
      if(self.lesson_id) doc_info.lesson_id = self.lesson_id
      if(self.training_event_id) doc_info.training_event_id = self.training_event_id
      doc_info.doc_type = self.doc_type
      doc_info.origin_file_name = file.name
      doc_info.file_name = file.name
      // file type
      let file_type = "other"
      if (file.type === "application/pdf") file_type = "pdf"
      if (file.type.includes("image/")) file_type = "img"
      if (file.type.includes("video/")) file_type = "video"
      if (file.type.includes("audio/")) file_type = "audio"
      doc_info.file_type = file_type

      // ファイルと画像で変える
      let upload_file = null
      let size_m_img_file = null
      let is_img = file.type.includes("image/")
      if(is_img){
        let baseFile = file
        if (file.type === 'image/heif' || file.type === 'image/heic'){
          baseFile = await heic2any({
            blob: file,
            toType: 'image/jpeg',
          });
        }
        const imgFile = await new Promise((resolve, reject) => {
          new Compressor(baseFile, {
            quality: 0.7,
            maxWidth: 1024,
            maxHeight: 1024,
            // mimeType: 'image/webp', // WebP 形式で保存（より圧縮率が高い）
            checkOrientation: true, // スマホ画像の向きを自動修正
            success: resolve,
            error: reject,
          });
        });
        const sizeMImgFile = await new Promise((resolve, reject) => {
          new Compressor(baseFile, {
            quality: 0.7,
            maxWidth: 250,
            maxHeight: 250,
            // mimeType: 'image/webp', // WebP 形式で保存（より圧縮率が高い）
            checkOrientation: true, // スマホ画像の向きを自動修正
            success: resolve,
            error: reject,
          });
        });
        upload_file = imgFile
        size_m_img_file = sizeMImgFile
      }else{
        upload_file = file
      }
      doc_info.file_size = upload_file.size

      doc_info = await this.docCreate(doc_info)
      console.log("doc_info", doc_info)
      if(is_img){
        await this.fileUpload(doc_info.img_presigned_url, upload_file, file_index)
        await this.fileUpload(doc_info.size_m_img_presigned_url, size_m_img_file, file_index, false)
      }else{
        await this.fileUpload(doc_info.file_presigned_url, upload_file, file_index)
      }
      await this.docUpdate(doc_info)
    },
    // モデル作成、アップロードURL作成
    async docCreate(doc_info){
      return await httpClient
        .post(`/cmp/floor/docs.json`, {doc: doc_info})
        .then(async (res) => {
          if (res.data.status === 'success') {
            return res.data.data.doc
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    // ファイルアップロード
    async fileUpload(upload_url, file, file_index, checkProgress = true){
      let self = this
      console.log("fileUpload", upload_url, file)
      await axios({
        method: 'put',
        url: upload_url,
        data: file,
        onUploadProgress: (progressEvent) => {
          // 進捗情報を取得
          if(checkProgress){
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(`Upload Progress: ${percentage}%`);
            this.percentageState = percentage;
            this.progre_set[file_index] = percentage
            this.updateAllProgre()
          }
        },
      })
        .then(async (res) => {
          // await self.videoInfoUpload(file, upload_info, videoInfo.duration)
        })
        .catch((error) => {
          // エラーハンドリング
          console.error('Upload Error:', error);
          self.isBusy = false
          window.storeCtl.commit("alert/setError", error.message)
        });
    },
    // アップロード完了更新
    async docUpdate(doc_info){
      let self = this
      return await httpClient
        .put(`/cmp/floor/docs/${doc_info.uid}.json`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.current_docs.push(res.data.data.doc)
            setTimeout(()=>{
              scrollToTop()
            }, 100)
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })

    },
  }
}
