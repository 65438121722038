
import _ from 'lodash';
import { training_set, training_list } from '~/utils/models'
import { httpClient } from '~/utils/axios'
import DocumentInputs from '~/components/util/docs/DocumentInputs'
import VideoInputs from '~/components/util/videos/VideoInputs'
import Common from '~/mixins/common'
import Training from '~/mixins/training'
// import draggable from "vuedraggable";
import EventSet from '~/components/common/lesson/EventSet'
import TrainingEventEdit from '~/components/common/training_event/Edit'
import TrainingListSelector from '~/components/common/training_list/Selector'
import QrReader from '~/components/util/QrReader'
import TrainingListEdit from '~/components/common/training_list/Edit'
import TrainingEventSelector from '~/components/common/lesson/TrainingEventSelector'
import Tabs from '~/components/util/Tabs'

export default {
  name: "LessonEdit",
  mixins: [Common,Training],
  components: {
    DocumentInputs,
    VideoInputs,
    // draggable,
    EventSet,
    TrainingEventEdit,
    TrainingListSelector,
    QrReader,
    TrainingListEdit,
    TrainingEventSelector,
    Tabs,
  },
  props: {
    lesson: Object,
    showClientEdit: {type: Boolean, default: false},
    showTabs: {type: Boolean, default: false},
  },
  data() {
    return {
      tab: 0,
      tabOptions: [
        {label: "体組成・計測", value: 0, disabled: false},
        {label: "種目", value: 1, disabled: false},
        {label: "コメント・メディア", value: 2, disabled: false},
      ],

      view_mode: "bodyscan",
      edit_lesson: null,
      showModal: false,
      cp_docs: [],
      currentClient: null,
      autoSaveOn: false,
      prevLesson: null,
      client_training_sets: [],
      client_history_videos: [],

      auto_save_timer: null,
    }
  },
  async mounted() {
    let self = this
    self.edit_lesson = _.cloneDeep(self.lesson)

    // 20秒に自動保存
    if (this.auto_save_timer) clearInterval(this.auto_save_timer) // 念のため
    this.auto_save_timer = setInterval(() => {
      // console.log("auto_save_timer start")
      if(
        this.showModal === true && 
        this.view_mode === 'training' && 
        this.lessonTrainingEventAutoSave === true
      ){
        // console.log("auto_save_timer save")
        this.update("save", false)
      }
    }, 1000 * 20)
  },
  beforeDestroy: function () {
    // console.log("beforeDestroy auto_save_timer desc")
    clearInterval(this.auto_save_timer)
  },
  watch: {
    lesson(val){
      this.edit_lesson = val
    },
  },
  computed: {
    showEventSetDetail:{
      get(){
        return this.$store.state.auth.showEventSetDetail
      },
      set(newVal){
        this.$store.commit("auth/setShowEventSetDetail", newVal)
      }
    },
    lessonTrainingEventAutoSave:{
      get(){
        return this.$store.state.auth.lessonTrainingEventAutoSave
      },
      set(newVal){
        this.$store.commit("auth/setLessonTrainingEventAutoSave", newVal)
      }
    },
    isBtnPrimary(){
      if(this.showTabs){
        if(this.edit_lesson.status === "done"){
          return true
        }else{
          return false
        }
      }else{
        if(this.edit_lesson.status === "done"){
          return true
        }else{
          if(this.view_mode === 'comment'){
            return false
          }else{
            return true
          }
        }
      }
    },
  },
  methods: {
    // フォーカス
    focusNext(index) {
      try{
        let refName = `input${index + 1}`
        this.$refs[refName].focus();
      }catch(e){
        console.log(e)
      }
    },
    // タブ
    changeTab(event){
      this.tab = event.newTabIndex
    },

    // 外部から開く処理
    async openLessonEdit(lesson, view_mode = "bodyscan"){
      this.edit_lesson = _.cloneDeep(lesson); 

      // 顧客に紐づく種目データ取得？
      this.updateClient()

      // 種目ごとの履歴データも取得
      this.updateClientTrainingEvent()

      // 前回レッスン情報を取得
      this.getPrevLesson()
      // マスタを最新化
      window.storeCtl.dispatch('company/getTrainingEvents')
      
      if(view_mode === "bodyscan") this.tab = 0
      if(view_mode === "training") this.tab = 1
      if(view_mode === "comment") this.tab = 2
      this.view_mode = view_mode
      this.showModal = true
    },
    async updateClient(){
      this.edit_lesson.client = await this.getClient(this.edit_lesson.client.uid)
    },
    async updateClientTrainingEvent(){
      // console.log("updateClientTrainingEvent")
      let training_event_ids = _.map(this.edit_lesson.training_events.event_sets, "training_event_id")
      await httpClient
        .get(`/cmp/floor/client_training_sets/get_by_event_ids.json`, {
          params: {
            client_id: this.edit_lesson.client.id,
            training_event_ids: training_event_ids,
          }
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            this.client_training_sets = res.data.data.client_training_sets
            this.client_history_videos = res.data.data.client_videos
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    filterd_client_training_sets(training_event_id){
      let client_training_sets =  _.filter(this.client_training_sets, cts => {
        return cts.training_event_id === training_event_id
      })
      client_training_sets = _.sortBy(client_training_sets, ['date']).reverse()
      return client_training_sets
    },
    filterd_client_history_videos(training_event_id){
      let client_history_videos =  _.filter(this.client_history_videos, cv => {
        return cv.training_event_of_client_id === training_event_id
      })
      client_history_videos = _.sortBy(client_history_videos, ['created_at']).reverse()
      return client_history_videos
    },
    filterd_client_current_videos(training_event_id){
      let client_current_videos =  _.filter(this.edit_lesson.videos, vd => {
        return vd.training_event_of_client_id === training_event_id
      })
      client_current_videos = _.sortBy(client_current_videos, ['created_at']).reverse()
      return client_current_videos
    },

    copyInnerMemoTempalte(){
      this.edit_lesson.inner_memo = _.cloneDeep(this.headquarter.lesson_inner_memo_template)
    },

    // 前回レッスンデータ ----------
    async getPrevLesson(){
      let self = this
      await httpClient
        .get(`/cmp/floor/lessons/list_by_ids.json`, {params: {lesson_ids: this.edit_lesson.client.prev_lesson_id}})
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.prevLesson = res.data.data.lessons.length > 0 ? res.data.data.lessons[0] : null
          } else {
            self.$store.commit("alert/setError", res.data.message)
          }
        })
    },
    prevDiffClass(current_data, prev_data, wanna_gain){
      if(current_data === null) return "text-info"
      if(current_data > prev_data){
        return wanna_gain ? "text-success" : "text-danger"
      }else{
        return wanna_gain ? "text-danger" : "text-success"
      }
    },
    prevDiffData(current_data, prev_data, unit = "kg"){
      if(current_data === null || prev_data === null) return null
      const diff = _.round((current_data - prev_data), 1)
      if(current_data > prev_data){
        return `/ ${diff}${unit}増`
      }else{
        return `/ ${diff}${unit}減`
      }
    },

    // ファイル------
    docsUpdated(new_docs){
      // console.log("docsUpdated", new_docs)
      this.edit_lesson.docs = new_docs
      this.edit_lesson.training_docs.ids = _.map(new_docs, "id")
    },
    videosUpdated(new_videos){
      // console.log("docsUpdated", new_docs)
      this.edit_lesson.videos = new_videos
      this.edit_lesson.training_docs.video_ids = _.map(new_videos, "id")
    },
    eventSetVideoUpdated(updated_videos, training_event_id){
      // 元の動画
      let new_videos = []
      this.edit_lesson.videos.forEach(lv => {
        if(lv.training_event_of_client_id !== training_event_id) new_videos.push(lv)
      })
      new_videos = new_videos.concat(updated_videos)
      this.edit_lesson.videos = new_videos
      this.edit_lesson.training_docs.video_ids = _.map(new_videos, "id")
    },

    inbodyDecoded(url){
      // let url = "https://qrcode.inbody.com?IBData=570-32PG600A6AJ!147!!!!!!!!!!!18300240M20230713173106027402560314017601580193011901110135042303830469021000890177045004140506057605320650061105640689082106260848111403380960018990245025603370962093303300940091202660949092210221049101810231050101903900690737-0084-0099+00151300000130195400130199401100236000310163900310161916900092008000900980111039303680450032002850348338734750261245223882975307002212205217925832667016819691958000000000PASS0!!!!!!!!!!!!!!101802046028501750123004260111046006260352073705910000015000850081000000000001120691110570DM-0422!!!!!!0220003510035102800009750097509750317038709190316038600810185025003000100020000"
      console.log("inbodyDecoded", url)
      // let data_str = url.replace("https://qrcode.inbody.com/?IBData=","")
      try{
        let data_str = url.split("=")[1]
        let code_type = data_str.substr(0, 6)
        console.log("code_type", code_type, data_str)
        if(code_type === "270-12"){
          this.edit_lesson.weight = parseInt(data_str.substr(118, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(150, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(176, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(146, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(355, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(90, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(102, 4)) / 10
          // 部位別筋肉量

        }
        if(code_type === "270-22"){
          window.storeCtl.commit("alert/setError", "対応していない機種です")
        }
        if(code_type === "270S-5"){
          this.edit_lesson.weight = parseInt(data_str.substr(125, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(158, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(184, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(154, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(323, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(97, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(109, 4)) / 10    
        }
        if(code_type === "270-32"){
          this.edit_lesson.weight = parseInt(data_str.substr(119, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(152, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(178, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(148, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(357, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(91, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(103, 4)) / 10    
        }
        if(code_type === "470-12"){
          window.storeCtl.commit("alert/setError", "対応していない機種です")
        }
        if(code_type === "470-32"){
          this.edit_lesson.weight = parseInt(data_str.substr(139, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(172, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(244, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(168, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(115, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(91, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(103, 4)) / 10
        }
        if(code_type === "570-12"){
          this.edit_lesson.weight = parseInt(data_str.substr(148, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(180, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(315, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(176, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(124, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(100, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(112, 4)) / 10
        }
        if(code_type === "570-32"){
          this.edit_lesson.weight = parseInt(data_str.substr(149, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(182, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(322, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(178, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(125, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(101, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(113, 4)) / 10
        }
        if(code_type === "380-62"){
          this.edit_lesson.weight = parseInt(data_str.substr(147, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(208, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(429, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(184, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(171, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(123, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(135, 4)) / 10
        }
      }catch(e){
        window.storeCtl.commit("alert/setError", "データのスキャンに失敗しました")
      }
    },
    // bmi自動計算
    calculateBMI() {
      if (this.lesson_setting.calcurate_bmi === false) return
      if (this.edit_lesson.client.height <= 0 || this.edit_lesson.weight <= 0) {
        window.storeCtl.commit("alert/setError", "身長と体重は正の数値である必要があります。")
      }
      const heightInMeters = this.edit_lesson.client.height / 100; // cm を m に変換
      const bmi = this.edit_lesson.weight / (heightInMeters ** 2);
      this.edit_lesson.bmi = parseFloat(bmi.toFixed(2)); // 小数点第2位まで丸める
    },

    // 種目 ------------------
    // 種目ソート
    // eventSetDragged(){
    //   window.storeCtl.commit("loading/start")
    //   let newCurrentEventSets = _.cloneDeep(this.edit_lesson.training_events.event_sets)
    //   this.edit_lesson.training_events.event_sets = []
    //   setTimeout(() => {
    //     this.edit_lesson.training_events.event_sets = newCurrentEventSets
    //     window.storeCtl.commit("loading/stop")
    //   }, 10)
    // },
    // 上に idx = 2 →　1
    sort(event){
      if(event.target_idx < 0 || (event.target_idx + 1) > this.edit_lesson.training_events.event_sets.length) return
      // console.log("sort",event.idx, event.target_idx)
      let new_event_sets = []
      let current_event_set = null
      // 自分以外を配列に入れて
      let index = 0
      this.edit_lesson.training_events.event_sets.forEach(es => {
        if( !es.sort_uid ) {
          // ソート用のUIDがない場合は新規作成
          es.sort_uid = this.generateUUID();
        }
        if(index === event.idx){
          current_event_set = es
        }else{
          new_event_sets.push(es)
        }
        index += 1
      })
      // 自分を特定を位置に入れる
      new_event_sets.splice(event.target_idx, 0, current_event_set)
      this.edit_lesson.training_events.event_sets = new_event_sets
    },

    // イベント選択イベント
    trainingEventSelected(training_event){
      let selectedTrainingEventCategory = _.find(this.categories, ["id", training_event.training_category_id])
      this.edit_lesson.training_events.event_sets.push({
        training_event_id: training_event.id,
        event_uid: training_event.uid,
        event: training_event,
        category: selectedTrainingEventCategory,
        training_sets: [_.cloneDeep(training_set)],
      })
    },
    // 複数選択
    trainingEventMultiSelected(training_events){
      training_events.forEach(te => {
        this.trainingEventSelected(te)
      })
      this.updateClientTrainingEvent()
    },
    // 種目追加
    addEventSets(event_sets){
      event_sets.forEach(es => {
        // 削除されてない種目のみ対応
        let training_event = _.find(this.training_events, ["uid", es.event_uid])
        if(training_event){
          this.edit_lesson.training_events.event_sets.push(es)
        }
      })
      this.updateClientTrainingEvent()
    },
    // 種目を追加して作成
    async updateTrainigMasterAndAddEvents(training_event){
      // マスタ更新
      await window.storeCtl.dispatch('company/getTrainingEvents')
      let selectedEvent = _.find(this.training_events, ["uid", training_event.uid])
      let selectedCategory = _.find(this.training_categories, ["id", selectedEvent.training_category_id])        
      this.edit_lesson.training_events.event_sets.push({
        training_event_id: training_event.id,
        event_uid: selectedEvent.uid,
        event: _.cloneDeep(selectedEvent),
        category: _.cloneDeep(selectedCategory),
        training_sets: [_.cloneDeep(training_set)],
      })
      this.updateClientTrainingEvent()
    },
    // 種目の削除
    removeTrainingEventAt(idx){
      let self = this
      self.$bvModal.msgBoxConfirm('この種目を削除してよろしいですか？', {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          let new_event_sets = _.cloneDeep(self.edit_lesson.training_events.event_sets)
          new_event_sets.splice(idx, 1)
          self.edit_lesson.training_events.event_sets = []
          setTimeout(()=>{
            self.edit_lesson.training_events.event_sets = new_event_sets
          }, 10)
        }
      })
    },
    changeEventSet(change_event_set, index){
      // console.log("changeEventSet", change_event_set, change_event_set.event_uid, index)
      let new_event_sets = _.cloneDeep(this.edit_lesson.training_events.event_sets)
      new_event_sets[index] = _.cloneDeep(change_event_set)
      this.edit_lesson.training_events.event_sets = new_event_sets
    },

    // トレーニングテンプレ追加
    addToTrainingList(){
      let new_training_list = _.cloneDeep(training_list)
      new_training_list.name = `${this.edit_lesson.client.name}様 ${this.edit_lesson.date}`
      new_training_list.training_events.event_sets = _.cloneDeep(this.edit_lesson.training_events.event_sets)
      this.$refs.training_list_edit.newTrainingList(new_training_list)
    },

    // 全体 -----------------------
    // 受講済み
    async updateDone(){
      let self = this
      self.$bvModal.msgBoxConfirm('この内容で顧客アプリが更新され、通知を発信します。', {
        title: '受講済みにしてよろしいですか？',
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          await self.update("done", true)
        }
      })
    },
    // 保存のみ
    async update(actionType = "save", closeModal = false){
      let self = this

      // 種目が選ばれていないのは削除
      let able_evet_sets = []
      let save_lesson = _.cloneDeep(self.edit_lesson)
      save_lesson.training_events.event_sets.forEach(event_set => {
        if(event_set.event) able_evet_sets.push(event_set)
      })
      save_lesson.training_events.event_sets = able_evet_sets
      
      // 閉めるならローダー出す
      return await httpClient
        .put(`/cmp/floor/lessons/${save_lesson.uid}.json`, {
          lesson: save_lesson,
          actionType: actionType,
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            window.storeCtl.commit("loading/stop")
            self.$emit("updated", res.data.data.lesson)
            if(!self.autoSaveOn) window.storeCtl.commit("alert/setSuccess", "保存しました")
            if(closeModal) self.showModal = false
            return true
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
            return false
          }
        })
    },
  },
}
