import { render, staticRenderFns } from "./DocumentInputs.vue?vue&type=template&id=59bf914a&scoped=true&lang=pug"
import script from "./DocumentInputs.vue?vue&type=script&lang=js"
export * from "./DocumentInputs.vue?vue&type=script&lang=js"
import style0 from "./DocumentInputs.vue?vue&type=style&index=0&id=59bf914a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59bf914a",
  null
  
)

export default component.exports