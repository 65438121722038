
import Common from '~/mixins/common'
import { client_training_event, eventHeadlines } from '~/utils/models'
import Training from '~/mixins/training'
import Tabs from '~/components/util/Tabs'
import Rating from '~/components/util/Rating'
import { debounce } from "lodash";

export default {
  mixins: [Common,Training],
  components: {
    Tabs,
    Rating,
  },
  props: {
    onlyEdit: {type: Boolean, default: false}
  },
  data() {
    return {
      showModal: false,
      edit_mode: "new",
      current_client: null,

      // 検索
      searchText: "",

      // マスタから
      selectedCat: null,
      selectedHeadlineType: "all",
      eventHeadlines: _.cloneDeep(eventHeadlines),
      currentEventHeadlines: [],
      katakanas: [
        "ア","イ","ウ","エ","オ","カ","キ","ク",
        "ケ","コ","サ","シ","ス","セ","ソ","タ",
        "チ","ツ","テ","ト","ナ","ニ","ヌ","ネ",
        "ノ","ハ","ヒ","フ","ヘ","ホ","マ","ミ",
        "ム","メ","モ","ヤ","ユ","ヨ","ラ","リ",
        "ル","レ","ロ","ワ",
      ],
      filterdEvents: [],

      selectedTrainingMachine: null,
      selectedTrainingMuscle: null,

      // 複数選択対応
      selectedEvents: [],

      tabIndex: 0,
      tabOptions: [
        {label: "カテゴリ", value: 0, disabled: false},
        {label: "機具・マシン", value: 1, disabled: false},
        {label: "鍛えられる部位", value: 2, disabled: false},
      ],
    }
  },
  async mounted() {
  },
  filters: {
  },
  computed: {
    ajusted_training_categories(){
      return [{id: null, name: "全て"}].concat(this.training_categories)
    },
    ajusted_training_machines(){
      return [{id: null, name: "全て"}].concat(this.training_machines)
    },
    ajusted_training_muscles(){
      return [{id: null, name: "全て"}].concat(this.training_muscles)
    },
    use_search_box_for_training_event:{
      get(){
        return this.$store.state.devise.use_search_box_for_training_event
      },
      set(newVal){
        this.$store.commit("devise/setUseSearchBoxForTrainingEvent", newVal)
      }
    },
    multiple_select_trainig_event:{
      get(){
        return this.$store.state.devise.multiple_select_trainig_event
      },
      set(newVal){
        this.$store.commit("devise/setMultipleSelectTrainigEvent", newVal)
      }
    },
    selectedEventNames(){
      let names = _.map(this.selectedEvents, "name")
      return names.join(",")
    },
  },
  methods: {
    changeTab(event){
      this.tabIndex = event.newTabIndex
      this.searchText = ""
      this.selectedHeadlineType = "all"
      this.selectedCat = {id: null, name: "全て"}
      this.selectedTrainingMachine = {id: null, name: "全て"}
      this.selectedTrainingMuscle = {id: null, name: "全て"}
      this.selectedEvents = []
      this.filterEvents()
      this.filterHeadlines()
    },
    open(edit_mode = "new", current_client = null){
      console.log("current_client",current_client)
      this.searchText = ""
      this.edit_mode = edit_mode
      this.current_client = current_client
      this.changeTab({newTabIndex: 0})
      this.showModal = true
    },
    trainingEventLog(training_event){
      let current_client_training_event = _.find(this.current_client.client_training_events, ["training_event_id", training_event.id])
      if(!current_client_training_event) current_client_training_event = _.cloneDeep(client_training_event)
      return current_client_training_event
    },
    filterEvents(){
      let events = _.cloneDeep(this.training_events)

      // カテゴリで絞り込み
      if(this.selectedCat && this.selectedCat.id !== null) events = _.filter(events, ["training_category_id", this.selectedCat.id])

      // 機材で絞り込み
      if(this.selectedTrainingMachine && this.selectedTrainingMachine.id !== null) events = _.filter(events, te => {return te.machine.includes(this.selectedTrainingMachine.id)})

      // 鍛えられる部位で絞り込み
      if(this.selectedTrainingMuscle && this.selectedTrainingMuscle.id !== null) events = _.filter(events, te => {return te.target_muscles.includes(this.selectedTrainingMuscle.id)})

      // 頭文字で絞り込み
      events.forEach(ev => {
        // 頭文字の属性を付与
        ev.headline_char = this.hiraToKana(ev.name.charAt(0))
        // 濁音をせいきか
        ev.headline_char = ev.headline_char.normalize('NFD')[0]
        if(this.katakanas.includes(ev.headline_char)){
          ev.headline_type = ev.headline_char
        }else{
          ev.headline_type = "other"
        }
      })
      if(this.selectedHeadlineType !== "all") events = _.filter(events, ["headline_type", this.selectedHeadlineType])
      
      this.filterdEvents = events
    },
    // 検索
    searchEventsDebounced: debounce(function () {
      this.searchEvents();
    }, 500), // 0.5秒後に実行
    searchEvents() {
      console.log("searchEvents", this.searchText);
      let searchText = this.searchText.toLowerCase(); // 小文字に変換
      let events = _.cloneDeep(this.training_events);
      if (searchText === "") return this.filterEvents();

      events = _.filter(events, ev => {
        return ev.name.toLowerCase().includes(searchText); // ev.name も小文字に変換して比較
      });

      this.filterdEvents = events;
    },

    // 種目選択
    filterHeadlines(){
      // 表示切り替え
      this.selectedHeadlineType = "all"

      // 見出しを存在するものだけに絞り込み
      let eventHeadlines = _.cloneDeep(this.eventHeadlines)
      eventHeadlines.forEach(hl => {
        if(hl.type === "all"){
          hl.active = true
        }else{
          let active = false
          this.filterdEvents.forEach(ev => {
            if(ev.headline_type === hl.type) active = true
          })
          hl.active = active
        }
      })
      this.currentEventHeadlines = eventHeadlines
    },
    selectCategory(cat){
      this.selectedCat = cat
      // カテゴリ
      this.selectEventHeadline({type: "all"})
      this.filterEvents()
      this.filterHeadlines()
    },
    selectEventHeadline(headline){
      this.selectedHeadlineType = headline.type
      this.filterEvents()
    },
    selectTrainingMachine(tm){
      this.selectedTrainingMachine = tm
      this.selectEventHeadline({type: "all"})
      this.filterEvents()
      this.filterHeadlines()
    },
    selectTrainingMuscle(tm){
      this.selectedTrainingMuscle = tm
      this.selectEventHeadline({type: "all"})
      this.filterEvents()
      this.filterHeadlines()
    },
    selectEvent(training_event){
      if(this.multiple_select_trainig_event && this.onlyEdit === false){
        this.selectedEvents.push(_.cloneDeep(training_event))
      }else{
        this.$emit("selected", training_event)
        this.showModal = false
      }
    },

    // 変換
    hiraToKana(str) {
      return str.replace(/[\u3041-\u3096]/g, function(match) {
          var chr = match.charCodeAt(0) + 0x60;
          return String.fromCharCode(chr);
      });
    },

    // 選択制御
    eventSelected(event){
      let hasEvent = _.find(this.selectedEvents, ["id", event.id])
      return !!hasEvent
    },
    addEvents(){
      this.$emit("multiSelected", this.selectedEvents)
      this.showModal = false
    },
  },
}
