
import { Line } from 'vue-chartjs'
// import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
import chartjsPluginAnnotation from 'chartjs-plugin-annotation'
import dayjs from 'dayjs';
import _ from 'lodash';
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  extends: Line,
  props: {
    client: Object,
    trends: Array,
  },
  components: {
    // ChartJsPluginDataLabels,
    // ChartJsPluginAnnotation,
  },
  computed: {
  },
  watch: {
    trends: function (trends) {
      // console.log("trends watch",trends)
      this.updateChart(trends)
    },
  },
  mounted() {
    this.addPlugin(chartjsPluginAnnotation)
    this.updateChart(this.trends);
  },
  data: function () {
    return {
      chartdata: {
        labels: [],
        datasets: [
          {
            yAxisID: "fat_rate",
            lineTension: 0,
            type: 'line',
            barPercentage: 0.3,
            label: '体脂肪率',
            backgroundColor: '#EB2F96',
            borderColor: '#EB2F96',
            fill: false,
            data: [],
            spanGaps: true, //値が抜け落ちていた時、埋めるか否か
          },
          {
            yAxisID: "weight",
            lineTension: 0,
            type: 'line',
            label: '体重',
            backgroundColor: '#2F54EB',
            borderColor: '#2F54EB',
            fill: false,
            data: [],
            spanGaps: true, //値が抜け落ちていた時、埋めるか否か
          },
          {
            yAxisID: "weight",
            lineTension: 0,
            type: 'line',
            barPercentage: 0.3,
            label: '筋肉量',
            backgroundColor: '#9254DE',
            borderColor: '#9254DE',
            fill: false,
            data: [],
            spanGaps: true, //値が抜け落ちていた時、埋めるか否か
            hidden: true,
          },
          {
            yAxisID: "weight",
            lineTension: 0,
            type: 'line',
            barPercentage: 0.3,
            label: '体脂肪量',
            backgroundColor: '#FFC53D',
            borderColor: '#FFC53D',
            fill: false,
            data: [],
            spanGaps: true, //値が抜け落ちていた時、埋めるか否か
            hidden: true,
          },
          {
            yAxisID: "weight",
            lineTension: 0,
            type: 'line',
            barPercentage: 0.3,
            label: '水分量',
            backgroundColor: '#85A5FF',
            borderColor: '#85A5FF',
            fill: false,
            data: [],
            spanGaps: true, //値が抜け落ちていた時、埋めるか否か
            hidden: true,
          },
        ]
      },
      options: {
        legend : {
          // display : false,
          position: "bottom", 
          labels: {
            fontSize: 10,
          },
          // 'onClick' : function (evt, item) {
          //   console.log ('legend onClick', evt, item);
          // },
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: { /* ここです */
          callbacks: {
            label: function(tooltipItem, data){
              // this.trendsが取れない
              // console.log("tooltips",tooltipItem,data)
              // console.log("date",this.trends,tooltipItem.label)
              // let trend = _.find(this.trends, ['date', tooltipItem.label])
              // console.log("trend",trend)
              // let message = `${tooltipItem.yLabel} / 食事：${trend.food} / 睡眠：${trend.sleep}`
              // return message
              return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: false,
              labelString: '日付'
            },
            ticks : {
              minRotation: 0,
              maxRotation: 0,
              autoSkip: true,
              maxTicksLimit: 4,
              callback: function(tick, index) {
                let str = dayjs(tick).format("YY/MM/DD");
                // let isRetern = false
                // if(index === 0) isRetern = true
                // if((index + 1) % 5 === 0) isRetern = true
                // return isRetern ? str : "";
                return str
              }
            }
          }],
          yAxes: [
            {
              id: "fat_rate",
              type: "linear",
              position: "left",
              ticks: {
                // min: 10,
                // max: 20,
                beginAtZero: false,
                maxTicksLimit: 20,
                stepSize: 0.5,
                callback: function(label, index, labels) { /* ここです */
                  return ((label % 1) == 0) ? label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "%" : ''
                }
              }
            },
            {
              id: "weight",
              type: "linear",
              position: "right",
              gridLines: {
                //y軸の網線
                display: false //表示するか否か
              },
              // https://tr.you84815.space/chartjs/axis/cartesian/linear.html
              ticks: {
                // min: 45,
                // max: 55,
                beginAtZero: false,
                maxTicksLimit: 20,
                stepSize: 0.5,
                callback: function(label, index, labels) { /* ここです */
                  return ((label % 1) == 0) ? label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "kg" : ''
                }
              }
            },
          ]
        },
        // 横線
        annotation: {
          annotations: [
            {
              type: 'line', // 線分を指定
              drawTime: 'afterDatasetsDraw',
              id: 'a-line-1', // 線のid名を指定（他の線と区別するため）
              mode: 'horizontal', // 水平を指定
              scaleID: 'fat_rate', // 基準とする軸のid名
              value: 15.6, // 引きたい線の数値（始点）
              endValue: 15.6, // 引きたい線の数値（終点）
              borderColor: '#DC05F5', // 線の色
              borderWidth: 3, // 線の幅（太さ）
              borderDash: [2, 2],
              borderDashOffset: 1,
              label: { // ラベルの設定
                backgroundColor: 'rgba(255,255,255,0.5)',
                bordercolor: '#DC05F5',
                borderwidth: 2,
                fontSize: 10,
                fontStyle: 'bold',
                fontColor: '#DC05F5',
                xPadding: 10,
                yPadding: 10,
                cornerRadius: 3,
                position: 'left',
                xAdjust: 0,
                yAdjust: 0,
                enabled: true,
                content: '目標体脂肪率'
              }
            },
            {
              type: 'line', // 線分を指定
              drawTime: 'afterDatasetsDraw',
              id: 'a-line-2', // 線のid名を指定（他の線と区別するため）
              mode: 'horizontal', // 水平を指定
              scaleID: 'weight', // 基準とする軸のid名
              value: 60, // 引きたい線の数値（始点）
              endValue: 60, // 引きたい線の数値（終点）
              borderColor: '#2614F7', // 線の色
              borderWidth: 3, // 線の幅（太さ）
              borderDash: [2, 2],
              borderDashOffset: 1,
              label: { // ラベルの設定
                backgroundColor: 'rgba(255,255,255,0.5)',
                bordercolor: '#2614F7',
                borderwidth: 2,
                fontSize: 10,
                fontStyle: 'bold',
                fontColor: '#2614F7',
                xPadding: 10,
                yPadding: 10,
                cornerRadius: 3,
                position: 'left',
                xAdjust: 0,
                yAdjust: 0,
                enabled: true,
                content: '目標体重'
              }
            }
          ]
        }
      }
    }
  },
  methods: {
    updateChart(trends){
      console.log("trends",trends)
      trends = _.orderBy(trends, 'date', 'asc');
      // データ加工
      this.chartdata.labels = []
      this.chartdata.datasets[0].data = []
      this.chartdata.datasets[1].data = []
      this.chartdata.datasets[2].data = []
      this.chartdata.datasets[3].data = []
      this.chartdata.datasets[4].data = []
      trends.forEach(tr => {
        this.chartdata.labels.push(tr.date)
        this.chartdata.datasets[0].data.push(tr.fat_rate)
        this.chartdata.datasets[1].data.push(tr.weight)
        this.chartdata.datasets[2].data.push(tr.muscle_weight)
        this.chartdata.datasets[3].data.push(tr.fat_weight)
        this.chartdata.datasets[4].data.push(tr.water_weight)
      })
      // 目標線
      if(this.company.use_goal && this.client.goal_fat_rate){
        this.options.annotation.annotations[0].scaleID = "fat_rate"
        this.options.annotation.annotations[0].value = this.client.goal_fat_rate
        this.options.annotation.annotations[0].endValue = this.client.goal_fat_rate
      }else{
        this.options.annotation.annotations[0].scaleID = "fat_rate_none"
      }
      if(this.company.use_goal && this.client.goal_weight){
        this.options.annotation.annotations[1].scaleID = "weight"
        this.options.annotation.annotations[1].value = this.client.goal_weight
        this.options.annotation.annotations[1].endValue = this.client.goal_weight
      }else{
        this.options.annotation.annotations[1].scaleID = "weight_none"
      }

      // 設定
      this.options.scales.xAxes[0].ticks.maxTicksLimit = this.$store.state.devise.isPC ? 10 : 4

      // ラベル
      this.chartdata.datasets[0].label = this.lesson_setting.fat_rate.title
      this.chartdata.datasets[1].label = this.lesson_setting.weight.title
      this.chartdata.datasets[2].label = this.lesson_setting.muscle_weight.title
      this.chartdata.datasets[3].label = this.lesson_setting.fat_weight.title
      this.chartdata.datasets[4].label = this.lesson_setting.water_weight.title
      this.chartdata.datasets[0].hidden = false // !this.lesson_setting.fat_rate.use
      this.chartdata.datasets[1].hidden = false // !this.lesson_setting.weight.use
      this.chartdata.datasets[2].hidden = true // !this.lesson_setting.muscle_weight.use
      this.chartdata.datasets[3].hidden = true // !this.lesson_setting.fat_weight.use
      this.chartdata.datasets[4].hidden = true // !this.lesson_setting.water_weight.use
      // legend
      let ng_names = []
      if(!this.lesson_setting.fat_rate.use) ng_names.push(this.lesson_setting.fat_rate.title)
      if(!this.lesson_setting.weight.use) ng_names.push(this.lesson_setting.weight.title)
      if(!this.lesson_setting.muscle_weight.use) ng_names.push(this.lesson_setting.muscle_weight.title)
      if(!this.lesson_setting.fat_weight.use) ng_names.push(this.lesson_setting.fat_weight.title)
      if(!this.lesson_setting.water_weight.use) ng_names.push(this.lesson_setting.water_weight.title)
      this.options.legend.labels.filter = function(legendItem, data) {
        if(ng_names.includes(legendItem.text)) return false
        return legendItem.text
      }
      // yAxes
      let self = this
      this.options.scales.yAxes[0].ticks.callback = function(label) {
        const unit = self.lesson_setting.fat_rate.tani; // '%'を取得
        return ((label % 1) == 0) ? label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + unit : ''
      }
      this.options.scales.yAxes[1].ticks.callback = function(label) {
        const unit = self.lesson_setting.weight.tani; // '%'を取得
        return ((label % 1) == 0) ? label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + unit : ''
      }

      this.renderChart(this.chartdata, this.options)

    },
  }
}
