
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { mapState } from 'vuex'
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
    client: Object,
    title: {type: String, default: null},
    message: {type: String, default: null},
  },
  data() {
    return {
      showAlert: false,
      showMessageModal: false,  
      current_client: null,
      edit_title: null,    
      edit_message: null,
    }
  },
  async mounted() {
    let self = this
    this.current_client = _.cloneDeep(this.client)
    this.edit_title = _.cloneDeep(this.title)
    this.edit_message = _.cloneDeep(this.message)
  },
  computed: {
    ...mapState({
    }),
  },
  methods: {
    openAuthMessage(client){
      this.current_client = _.cloneDeep(client)
      this.showAlert = true
      this.edit_title = "ログイン情報のお知らせ"
      let message = _.cloneDeep(this.$store.state.auth.company.auth_message)
      message = _.replace(message, "%name%", this.current_client.name)
      message = _.replace(message, "%id%", this.current_client.uid)
      if(this.staff.can_view_client_pass) message = _.replace(message, "%password%", this.current_client.pass_text)
      message = _.replace(message, "%email%", this.current_client.email)
      this.edit_message = message
      this.showMessageModal = true
    },
    copyLoginMessage(){
      console.log("this.edit_message",this.edit_message)
      this.$copyText(this.edit_message).then(function (e) {
        window.storeCtl.commit("alert/setSuccess", "コピーしました")
      }, function (e) {
        // alert('Can not copy')
      })      
    },

    openMessage(client, title, message){
      this.current_client = _.cloneDeep(client)
      this.edit_title = title
      this.edit_message = message
      this.showMessageModal = true
    },
    async sendMessage(){
      let self = this
      self.$store.commit("loading/start")
      await httpClient
        .post(`/cmp/floor/clients/${self.current_client.uid}/send_message.json`, {
          title: self.edit_title,
          message: self.edit_message,
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            window.storeCtl.commit("loading/stop")
            self.showMessageModal = false
            self.$emit("send")
            window.storeCtl.commit("alert/setSuccess", "送信しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
  },
}
