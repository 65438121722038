
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { form_config } from '~/utils/models'
import Common from '~/mixins/common'
import ClientCard from '~/components/common/client/Card'
import SurveyQuestion from '~/components/common/survey/Question'
import EditClientCourse from '~/components/common/client/EditCourse'
import DocumentInputs from '~/components/util/docs/DocumentInputs'

export default {
  mixins: [Common],
  components: {
    ClientCard,
    SurveyQuestion,
    EditClientCourse,
    DocumentInputs,
  },
  props: {
  },
  data() {
    return {
      step: 1,
      edit_client: null,
      showModal: false,
      showCardModal: false,

      sexOptions: [
        { value: 'male', text: '男性' },
        { value: 'female', text: '女性' },
      ],
      terms: false,
      custom_term_1: false,
      custom_term_2: false,
      custom_term_3: false,
      custom_term_4: false,
      custom_term_5: false,

      payment_type: "card",
      edit_survey: null,

      bg_color: "#c2c2c2",


      // 閉じるパスワード
      showCloseComfirmModal: false,
      closePassword: null,

    }
  },
  async mounted() {
  },
  watch: {
  },
  computed: {
    selectedCourse(){
      return _.find(this.courses, ["id", this.edit_client.course_id])
    },
    next_price_sum(){
      let price_sum = this.edit_client.subsc_settings.current_cart.course_1.price
      this.edit_client.subsc_settings.current_cart.orders.forEach(order => {
        if(order.is_subscription) price_sum += order.sub_total
      })
      return price_sum
    },
    termDisable(){
      return !this.terms || 
        (this.headquarter.settings.join_setting.custom_term_1.use && !this.custom_term_1) || 
        (this.headquarter.settings.join_setting.custom_term_2.use && !this.custom_term_2) || 
        (this.headquarter.settings.join_setting.custom_term_3.use && !this.custom_term_3) || 
        (this.headquarter.settings.join_setting.custom_term_4.use && !this.custom_term_4) || 
        (this.headquarter.settings.join_setting.custom_term_5.use && !this.custom_term_5)
    }
  },
  methods: {
    client_join_start(client) {
      this.edit_client = _.cloneDeep(client)
      if(this.headquarter.can_contract_by_cmp){
        this.step = 1
        this.payment_type = "card"
        this.edit_survey = null
        this.terms = false
        this.custom_term_1 = false
        this.custom_term_2 = false
        this.custom_term_3 = false
        this.custom_term_4 = false
        this.custom_term_5 = false
        this.payment_type = this.company.can_card ? "card" : "shop"
        this.surveyInit()
        this.showModal = true
        
        // 背景色黒く
        setTimeout(() => {
          const element = document.querySelector('.modal-backdrop');
          if (element) {
            element.style.opacity = '1';
            element.style.backgroundColor = 'rgba(0, 0, 0, 1)';
          }
        }, 100);

      }else{
        this.join_old()
      }
    },

    updateData(client){
      this.edit_client = client
      this.$emit("updated", client)
    },
    async changeZipcode(){
      let address = await this.searchAddress(this.edit_client.zipcode)
      if(address) this.edit_client.address = address
    },
    changeBirthDate(){
      if(!this.edit_client.birth_date) return
      // 生年月日をDateオブジェクトに変換
      let birthDateObj = new Date(this.edit_client.birth_date);
      // 現在の日付を取得
      let currentDate = new Date();
      // 生まれてからの経過時間をミリ秒で計算
      let elapsedTime = currentDate - birthDateObj;
      // ミリ秒から年齢に変換
      let age = Math.floor(elapsedTime / (1000 * 60 * 60 * 24 * 365.25));
      this.edit_client.age = age
    },
    // ファイル------
    docsUpdated(new_docs){
      // console.log("docsUpdated", new_docs)
      this.edit_client.docs = new_docs
      this.edit_client.settings.cp_doc_ids = _.map(new_docs, "id")
    },

    // アンケート
    async surveyInit(){
      await httpClient
        .get(`/cmp/floor/surveys/get_by_scene.json?use_scene=client_join`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            let survey = res.data.data.survey
            let survey_questions = res.data.data.survey_questions
            if(survey) survey.survey_questions = _.sortBy(survey_questions, "sort")
            this.edit_survey = survey
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })      
    },
    surveyUpdated(survey){
      // console.log("surveyUpdated",survey)
      this.edit_survey = survey
    },

    // ステップ進行処理
    async stepNext(){
      if(this.step === 1) await this.updateClient()
      if(this.step === 3){
        this.joinCourse()
      }else{
        this.step += 1
      }
    },
    stepBack(){
      if(this.step === 1){
        this.showModal = false
      }else{
        this.step -= 1
      }
    },

    onBeforeHide(event){
      event.preventDefault();
      this.closePassword = null
      this.showCloseComfirmModal = true
    },
    submitHide(){
      const today = new Date();
      const month = (today.getMonth() + 1).toString().padStart(2, '0'); // 月 (01〜12)
      const day = today.getDate().toString().padStart(2, '0'); // 日 (01〜31)
      const mmdd = month + day; // MMDD形式
      const reversed = mmdd.split('').reverse().join(''); // 逆順に変換
      const password = `gym${reversed}` // 今日が12/06 -> gym6021
      if(this.closePassword === password){
        this.showCloseComfirmModal = false
        this.showModal = false
      }else{
        window.storeCtl.commit("alert/setError", "パスワードが違います")
      }
    },  

    // 申し込み処理
    async joinCourse(){
      let self = this
      window.storeCtl.commit("loading/start", true)
      await httpClient
        .put(`/cmp/floor/clients/${this.edit_client.uid}/join.json`, {
          type: "with_course",
          client: this.edit_client,
          payment_type: this.payment_type,
          survey: this.edit_survey,
          cp_doc_ids: this.edit_client.settings.cp_doc_ids
        })
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            self.$emit('updated', res.data.data.client)
            this.step = 4
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    // 顧客情報の更新
    async updateClient(){
      let self = this
      // form
      const formData = new FormData();
      // other
      let client_obj = JSON.stringify(self.edit_client)
      formData.append('client_obj', client_obj)
      let res = await httpClient
        .put(`/cmp/floor/clients/${this.edit_client.uid}.json`, formData, form_config)
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.edit_client = res.data.data.client
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },

    //- 入会
    async join_old() {
      let self = this
      self.$bvModal
        .msgBoxConfirm(
          `${this.edit_client.name}様の入会処理してよろしいですか？`,
          {
            okTitle: 'はい',
            cancelTitle: 'いいえ',
            centered: true
          }
        )
        .then(async confirm => {
          if (confirm) {
            self.$store.commit('loading/start')
            await httpClient
              .put(`/cmp/floor/clients/${this.edit_client.uid}/join.json`)
              .then(async res => {
                window.storeCtl.commit('loading/stop')
                if (res.data.status === 'success') {
                  self.$emit('updated', res.data.data.client)
                  window.storeCtl.commit('alert/setSuccess', '処理しました')
                  self.$refs.edit_client_course.openClientCourse(
                    res.data.data.client
                  )
                } else {
                  window.storeCtl.commit('alert/setError', res.data.message)
                }
              })
          }
        })
    }

  },
}
