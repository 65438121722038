
import Common from '~/mixins/common'
import SideMenu from '~/components/util/SideMenu'
import UserIcon from '~/components/util/UserIcon'
import Alert from '~/components/util/Alert'
import Loading from '~/components/util/Loading'
import ConfirmDialog from '~/components/util/ConfirmDialog'
import ModalFirstContact from '~/components/common/ModalFirstContact'
import MultiAuth from '~/components/common/MultiAuth'
import AccountInfoModal from '~/components/common/AccountInfoModal'
import ClientSearch from '~/components/common/client/Search'
import CompanyboardEdit from '~/components/common/company/BoardEdit'

// timezone
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'default',
  mixins: [Common],
  components: {
    Alert,
    Loading,
    ConfirmDialog,
    UserIcon,
    ModalFirstContact,
    SideMenu,
    MultiAuth,
    ClientSearch,
    AccountInfoModal,
    CompanyboardEdit
  },
  data() {
    return {}
  },
  async mounted() {
    let self = this
    self.$nuxt.$root.$confirm = this.$refs.confirm
  },
  wath: {},
  computed: {
    drawer: {
      get() {
        return this.$store.state.devise.drawer
      },
      set(newValue) {
        this.$store.commit('devise/setDrawer', newValue)
      }
    },
    showPayment() {
      if (this.staff.can_settings === false) return false
      if (this.company.hide_payment && this.$store.state.userAgent.isIosApp)
        return false
      return true
    },
    timeZone() {
      return dayjs.tz.guess();
    },
    timeZoneError() {
      const currentTimezone = dayjs.tz.guess().toLowerCase()
      if (currentTimezone !== "asia/tokyo" && currentTimezone !== "etc/gmt-9") {
        return true
      }
      return false
    }
  },
  methods: {
    changeShowBorad() {
      this.$store.commit('devise/changeShowBorad')
    },
    clickDrower() {
      this.$store.commit('devise/setDrawer', !this.$store.state.devise.drawer)
    },
    async reaload() {
      let self = this
      // ここで一度マスターをすべて取得
      self.$store.commit('loading/start')
      await self.$store.dispatch('auth/mstForceUpdate')
      self.$store.commit('loading/stop')
      self.$store.commit('event/updateReload')
    }
  }
}
