
import _ from 'lodash';
import { form_config } from '~/utils/models'
import { httpClient } from '~/utils/axios'
import { mapState } from 'vuex'
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
    client: Object,
    title: {type: String, default: null},
    message: {type: String, default: null},
  },
  data() {
    return {
      showMessageModal: false,  
      edit_title: null,    
      edit_message: null,
      toType: "active",
      toOptions: [
        { text: 'すべて', value: null },
        { text: 'ビジター', value: "trial" },
        { text: '会員', value: "active" },
        { text: '停止中', value: "disactive" },
      ],
      handleSelect: false,
      clients: [],
      toClientIds: [],
    }
  },
  async mounted() {
    let self = this
    if(this.headquarter.client_limit_only_active){
      this.toOptions = [
        { text: 'すべて', value: null },
        { text: 'ビジター', value: "trial" },
        { text: '会員', value: "active" },
      ]
    }
    this.edit_title = _.cloneDeep(this.title)
    this.edit_message = _.cloneDeep(this.message)
  },
  computed: {
    ...mapState({
    }),
  },
  methods: {
    openSelectedMessage(clients){
      this.toType = null
      this.clients = clients
      this.toClientIds = _.map(clients, "id")
      this.handleSelect = true
      this.edit_title = null
      this.edit_message = null
      this.showMessageModal = true
    },
    openMessage(title, message){
      this.toType = "active"
      this.clients = []
      this.toClientIds = []
      this.handleSelect = false
      this.edit_title = title
      this.edit_message = message
      this.showMessageModal = true
    },
    async handleSelectOn(){
      let self = this
      window.storeCtl.commit("loading/start")
      let query = self.toType ? `?status_eq_any=${self.toType}` : null
      await httpClient
        .get(`/cmp/floor/clients/index_light.json${query}`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            this.clients = res.data.data.clients
            this.toClientIds = _.map(this.clients, "id")
            this.handleSelect = true
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })      
    },
    handleSelectOff(){
      this.handleSelect = false
      this.client_ids = []
    },
    async sendMessage(){
      let self = this
      self.$store.commit("loading/start")
      await httpClient
        .post(`/cmp/floor/clients/send_message_to_any.json`, {
          to_type: self.toType,
          client_ids: self.toClientIds,
          title: self.edit_title,
          message: self.edit_message,
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            window.storeCtl.commit("loading/stop")
            self.showMessageModal = false
            self.$emit("send")
            window.storeCtl.commit("alert/setSuccess", "送信リクエストを受け付けました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
  },
}
